import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { faqTextTypography,faqHTMLTypography } from '../../helpers/typographies'
import useWindowDimensions from '../../helpers/windowDimensions'
import { videoInsert } from '../../helpers/videos'
import { positionChangeWidthSm, simcapTurquoise, simcapPink } from '../../helpers/variableDefaults.js'


// FAQ Page
const FAQ = (props) => {

  // console.log('props.fromComponent ->', props.fromComponent)
  // Destructure Props
  const { fromComponent } = props

  // Window Dimensions
  const { height, width } = useWindowDimensions()
  // console.log('width ->', width)

  // URL Path
  // const urlPath = window.location.pathname

  // Standard Question and Answer Element
  // Here and not in a helper file for convenience — it only appears here
  const questionAnswerComponent = (question = '', answer = '', expandedDefault = false, isVideo = false, videoLink = '', writtenInstructions = '', altText = '', cardWidth = '95%' , width = 0) => {
    return (
      // Instantiate an Accordion
      <Accordion
        defaultExpanded={expandedDefault}
        sx={{ 
          width: '100%',
          boxShadow: 4, 
        }}
      >
        {/* Question appears on Accordion Summary */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: 'lightblue',
            border: 2,
            borderBottom: 2,
            borderColor: 'black',
          }}
        >
          <Typography>
            <strong>{question}</strong>
          </Typography>
        </AccordionSummary>

        {/* Answer Appears in Accordion Details */}
        <AccordionDetails
          sx={{
            // backgroundColor: 'lightyellow',
            backgroundColor: 'mistyrose',
            // backgroundColor: 'lavenderblush',
            border: 2,
            borderTop: 0,
            borderColor: 'black',
            display: 'flex', flexDirection: 'column',
          }}
        >
          {/* {answer.length > 0 && faqTextTypography(answer, '18px', 'Lato')} */}
          {answer.length > 0 && faqHTMLTypography(answer, '18px', 'Lato')}
          {isVideo && videoInsert(videoLink, altText, cardWidth, width)}
          {writtenInstructions.length > 0 && faqTextTypography(writtenInstructions, '18px', 'Lato')}
        </AccordionDetails>

      </Accordion>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {/* {fromComponent === 'faq' && 
        seoPageTags(
          'FAQ'
        )
      } */}

      {/* FAQ Body */}
      <Container sx={{ 
        // backgroundColor: 'orange',
        // height: `calc(100vh - ${navbarHeight})`, 
        width: '100vw', 
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
      }}>

        {/* This Box is necessary for scrolling and view height normalization */}
        <Box
          sx={{
            // backgroundColor: 'orange',
            width: '100vw',
            height: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >

          {/* Box sets max width and new background color */}
          <Box
            sx={{
              backgroundColor: 'whitesmoke',
              boxShadow: 4,
              mb: fromComponent === 'faq' ? 4 : 0, mt: 0, pt: (fromComponent === 'faq' || fromComponent === 'familyHQ') ? 4 : 1, pb: 2,
              width: '100vw',
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start',
            }}
          >

            {/* Box sets smaller interior width for the question accordions */}
            <Box
              sx={{ 
                pb: 8,
                width: .90, 
                maxWidth: '600px',
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Title */}
              {(fromComponent === 'home') && 
                <Typography
                  sx={{
                    fontSize: '24px',
                    mb: -1,
                  }}
                >
                  <h3>About SimCap</h3>
                </Typography>
              }

              {/* FAQ Accordions */}

              {/* Panel 1 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'What is SimCap?',
                  // 'SimCap is a software platform for simulating venture capitalist decision making, useful in both job-interviewing and educational environments.',
                  `
                    <div>
                      SimCap is a game for learning the risky judgments 
                      early-stage venture capital (“VC”) firms’ 
                      investment committees (“ICs”) must make.
                    </div>
                    </br>
                    <div>
                      The game’s user is an IC member whose diligence team 
                      presents a suite of startups with a range of strengths and 
                      weaknesses. Based on information presented, the user 
                      determines whether to invest and, of the investments, 
                      which subset is worthy of continued investment.
                    </div>
                    </br>
                    <div>
                      Outputs entail both performance and behavioral insights. 
                      Performance metrics discern the quality of the user’s 
                      judgment making investment decisions. Behavioral insights 
                      include understanding risks the user considers relatively 
                      more acceptable, strengths to which the user gravitates,
                      whether the user prefers to keep companies alive or bet on
                      winners in follow-on investing, and the pace at which the 
                      user deploys capital.
                    </div>
                  `,
                  true
                )
              }

              {/* Panel 2 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'Who should use SimCap?',
                  `
                    <div>
                      SimCap distills quality and style of decisions users would 
                      make as VCs, thereby enabling both learning and evaluation 
                      of VC judgment. Primary user groups include:
                    </div>
                    </br>
                    <div>
                      <span style="font-weight:bold; color:black;">VC Fund Investors</span>
                      </br>
                      Learn emerging VC fund managers’ judgments and behaviors. 
                      Simulations are top tools of sophisticated hiring 
                      processes, and investors into VC funds effectively “hire” 
                      VCs to manage capital.
                    </div>
                    </br>
                    <div>
                      <span style="font-weight:bold; color:black;">VC Fund Managers</span> 
                      </br>
                      Evaluate Principals and Associates 
                      when hiring. Fund managers must trust judgments of 
                      team members evaluating prospective  investments, 
                      so the quality of companies that reach IC is high.
                    </div>
                    </br>
                    <div>
                      <span style="font-weight:bold; color:black;">Students</span>
                      </br> 
                      Learn the job of a VC fund manager.
                    </div>
                    </br>
                    <div>
                      <span style="font-weight:bold; color:black;">Entrepreneurs</span> 
                      </br>
                      Strengthen understanding of how 
                      investors evaluate startups.
                    </div>
                  `,
                  false
                )
              }

              {/* Panel 3 */}
              {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'Why SimCap?',
                  `
                    <div>
                      MBA programs deploy simulations for teaching subjects ranging 
                      from startup management to negotiations to understanding 
                      OPEC. Hiring managers ranging from finance to computer 
                      programming filter candidate pools using simulated problems 
                      tailored to the profession as a best practice.
                    </div>
                    </br>
                    <div>
                      If you are seeking to learn VC or evaluate a candidate’s 
                      fitness for a VC role, SimCap is for you.
                    </div>
                  `,
                  false
                )
              }
              
              {/* Panel 4 */}
              {/* {(fromComponent === 'faq' || fromComponent === 'home') && 
                questionAnswerComponent(
                  'How does SimCap work?',
                  `— An administrator will create a new test in the User Dashboard by clicking 'New Simulation' and then inputting names and emails of the test takers. 
                  — When a new test is created, the link and the security code are emailed to the taker. 
                  — The taker completes the test, submits it, and detailed results will be ready within minutes. 
                  — A link to the results is sent to both the taker and the administrator, and an index of all administered simulations is available to the administrator in the User Dashboard.`,
                  false
                )
              } */}


            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default FAQ